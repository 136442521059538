import * as React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/layout';
import { CheckIcon, PlusIcon } from '@heroicons/react/outline';
import Magnifying from '../images/svg/magnifying.svg';
import Warning from '../images/svg/warning.svg';
import LightbulbCircuit from '../images/svg/lightbulb-circuit.svg';
import Users from '../images/svg/users.svg';
import { GoldToppedHeader } from '../components/molecules';
import { Link, useTranslation, Trans } from 'gatsby-plugin-react-i18next';

export default function Pricing() {
  const { t } = useTranslation();

  const features = [
    {
      name: t('Best of AI, semantic, and traditional search'),
      icon: Magnifying,
      description: t(
        'Choose from two AI ranking modes to search over 140 million patents. Customize and refine results with robust filters to suit any need from novelty, to invalidity, to FTO.'
      ),
    },
    {
      name: t('Create unlimited monitors and patent alerts'),
      icon: Warning,
      description: t(
        'Keep track of the latest patents in your field, identify technology trends early, and monitor competitor activity.'
      ),
    },
    {
      name: t('Securely and effortlessly organize your IP'),
      icon: LightbulbCircuit,
      description: t(
        'From idea to patent and beyond organize all of your invention and intellectual property in one secure place.'
      ),
    },
    {
      name: t('Share, discuss, and annotate with unlimited viewers'),
      icon: Users,
      description: t(
        'Add unlimited team members as Viewers who can discuss projects and annotate patents. Purchase creator licenses to create and share projects.'
      ),
    },
  ];

  const checklist = [
    t('Unlimited projects'),
    t('Unlimited viewers'),
    t('Search 140m+ patents'),
    t('Share up to 50 projects'),
    t('Discuss and annotate'),
    t('Cancel any time'),
  ];

  return (
    <Layout
      title={t('Pricing')}
      description={t(
        'Simple pricing for teams of any size. Everything you need in one place.'
      )}
    >
      <div className="text-center pt-24 pb-16">
        <GoldToppedHeader
          header={t('Simple pricing for teams of any size')}
          topper={t('Pricing')}
        />
        <div className="mt-4 font-display text-gray_4 text-xl py-4">
          <Trans>Everything you need in one place</Trans>
        </div>
      </div>
      <div className="relative bg-gray_0.5">
        <div className="relative max-w-7xl mx-auto lg:px-8 lg:grid lg:grid-cols-2">
          <div className="py-16 px-4 sm:py-24 sm:px-6 lg:px-0 lg:pr-8">
            <div className="max-w-lg mx-auto lg:mx-0">
              <dl className="space-y-10">
                {features.map((feature) => (
                  <div key={feature.name} className="relative">
                    <dt>
                      <div className="absolute h-12 w-12 flex items-center justify-center bg-indigo-500 rounded-md">
                        <feature.icon
                          className="h-12 w-12 text-white"
                          aria-hidden="true"
                        />
                      </div>
                      <p className="ml-16 text-lg leading-6 font-medium text-chocolate tracking-tight font-display">
                        {feature.name}
                      </p>
                    </dt>
                    <dd className="mt-2 ml-16 text-base text-gray_4">
                      {feature.description}
                    </dd>
                  </div>
                ))}
              </dl>
            </div>
          </div>
          <div className="bg-indigo-700 py-16 px-4 sm:py-24 sm:px-6 lg:bg-none lg:px-0 lg:pl-8 lg:flex lg:items-center lg:justify-end">
            <div className="max-w-lg mx-auto w-full space-y-8 lg:mx-0">
              <div>
                <h2 className="sr-only">
                  <Trans>Price</Trans>
                </h2>
                <p className="relative grid grid-cols-1">
                  <span className="flex flex-col text-center">
                    <span className="text-3xl sm:text-5xl font-extrabold text-chocolate tracking-tight">
                      <Trans>Starting from $500/mo</Trans>
                    </span>
											<p>
                    <span className="text-xl sm:textd5xl font-extrabold text-chocolate tracking-tight">
                      <Trans>when paid annually</Trans>
                    </span>
		    </p>
                  </span>
                </p>
              </div>
              <ul className="rounded overflow-hidden grid gap-px sm:grid-cols-2">
                {checklist.map((item) => (
                  <li
                    key={item}
                    className="py-4 px-4 flex items-center space-x-3 text-base text-chocolate"
                  >
                    <CheckIcon
                      className="h-6 w-6 text-utility_green"
                      aria-hidden="true"
                    />
                    <span>{item}</span>
                  </li>
                ))}
              </ul>
              <Link
                to="/request-trial-account"
                className="bg-pastel_red rounded-md w-full px-8 py-4 flex items-center justify-center text-lg leading-6 font-medium text-white hover:bg-pastel_red_120 md:px-10"
              >
                <Trans>Get started today</Trans>
              </Link>
              <div className="w-full font-display text-sm text-chocolate text-center">
                <Trans>
                  <Link
                    to="/get-in-touch"
                    className="text-pastel_red hover:text-pastel_red_120"
                  >
                    Get in touch
                  </Link>{' '}
                  for team pricing and Enterprise options.
                </Trans>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
